import { ChannelData } from '@innedit/innedit';
import { ChannelType, DocumentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';

import { MenuType } from '../../../../../components/Menu';
import CMSView from '../../../../../components/View';
import Form from '../../../../../containers/Espace/Form';
import IconEye from '../../../../../icons/Eye';
import canalParams from '../../../../../params/canal/apercu.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const ChannelsPage: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { channelId, espaceId },
  } = props;
  const [channel, setChannel] = useState<DocumentType<ChannelType>>();
  useEffect(() => {
    const model = new ChannelData({
      espaceId,
    });

    const unsub = model.watchById(channelId, setChannel);

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [channelId]);

  const menu: MenuType = { left: [], right: [] };

  if (!channel) {
    return null;
  }

  if (!menu.right) {
    menu.right = [];
  }

  if ('api' === channel.kind) {
    menu.right.push({
      color: 'neutral',
      iconLeft: IconEye,
      target: '_blank',
      text: 'Afficher les produits',
      to: `${process.env.GATSBY_INNEDIT_API_URL}/channels/${channelId}/produits`,
      variant: 'ghost',
    });
  }

  if ('website' === channel.kind && channel.url) {
    menu.right.push({
      color: 'neutral',
      iconLeft: IconEye,
      target: '_blank',
      text: 'Afficher la boutique',
      to: channel.url,
      variant: 'ghost',
    });
  }

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          displayAdd={false}
          displayClose={false}
          displayNavigation={false}
          displayRemove={false}
          docId={channelId}
          itemPathnamePrefix={`/espaces/${espaceId}/canaux/`}
          menu={menu}
          model={
            new ChannelData({
              espaceId,
              itemFieldTitle: 'libelle',
              params: canalParams,
            })
          }
          name="item"
          params={{
            espaceId,
            user,
          }}
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(ChannelsPage);
